import Mixins from '@/plugins/mixins'
export default {
  namespaced: true,
  state: {
    showDialog: false,
    loadingTariffs: false,
    collectedTariffs: [],
    tariffs: [],
    termsByTariff: {},
    calculations: [],
    loading: false,
    currentConditions: {
      tariff: null,
      summ: '',
      amountType: 'cart_amount',
      term: null,
    },
    calculationSelected: {
      amount: 0,
      customer_overpayment_amount: 0,
      customer_total_payments: 0,
      recurring_payment: 0,
    },
  },
  mutations: {
    updateLoadingTariffs(state, value) {
      state.loadingTariffs = value
    },
    updateLoading(state, value) {
      state.loading = value
    },
    updateCollectedTariffs(state, value) {
      state.collectedTariffs = value
    },
    updateTermsByTariff(state, value) {
      state.termsByTariff = value
    },
    updateTariffs(state, data) {
      state.tariffs = data
    },
    updateCalculations(state, data) {
      state.calculations = data
    },
    updateCurrentConditions(state, value) {
      state.currentConditions = value
    },
    updateCalculationSelected(state, value) {
      state.calculationSelected = value
    },
  },
  actions: {
    previewAmounts(context, data) {
      let payload = data.payload
      let conditions = data.conditions
      let url = Mixins.methods.removeTrailingSlash(VUE_APP_API) + '/lending-billing/api/calculator/preview-amounts'
      context.commit('updateLoading', true)
      context.dispatch('resetCalculations')
      this.$axios
        .post(url, payload)
        .then((response) => {
          context.commit('updateLoading', false)
          if (response.status === 200) {
            // Первым делом сверяемся, что действительно пришли данные к тому запросу, который сейчас в калькуляторе установлен.
            if (
              parseInt(context.state.currentConditions.summ) === conditions.summ &&
              context.state.currentConditions.tariff === conditions.tariff &&
              context.state.currentConditions.amountType === conditions.amountType
            ) {
              context.commit('updateCalculations', response.data.calculations)
              context.dispatch('chooseCalculationByTerm')
            }
          }
        })
        .catch(function (error) {
          context.commit('updateLoading', false)
        })
    },
    chooseCalculationByTerm(context) {
      if (context.state.currentConditions.term && context.state.currentConditions.term != null) {
        context.state.calculations.forEach((calc) => {
          let currentTerm = calc.term
          if (typeof calc.term === 'number') {
            currentTerm = calc.term.toString()
          }
          if (currentTerm.trim() === context.state.currentConditions.term) {
            let calculationSelected = {
              amount: Math.abs(calc.calculation_amount.amount),
              customer_overpayment_amount: calc.calculation_amount.customer_overpayment_amount,
              customer_total_payments: calc.calculation_amount.customer_total_payments,
              recurring_payment: calc.calculation_amount.recurring_payment,
            }
            context.commit('updateCalculationSelected', calculationSelected)
          }
        })
      }
    },
    getActualTariffs(context) {
      context.commit('updateLoadingTariffs', true)
      let cid = context.rootState.account.mandarin.client_id
      let url = Mixins.methods.removeTrailingSlash(VUE_APP_API) + '/lending-billing/api/merchant-fees/' + cid + '/actual'
      this.$axios
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            context.commit('updateLoadingTariffs', false)
            if (Object.prototype.hasOwnProperty.call(response.data, 'merchant_fees')) {
              let tariffs = []
              let termsByTariff = []
              response.data.merchant_fees.forEach((fee) => {
                if (!collectedTariffs.value.includes(fee.pricing_model.id)) {
                  collectedTariffs.value.push(fee.pricing_model.id)
                  let tariff = {
                    title: fee.pricing_model.name,
                    value: fee.pricing_model.id,
                  }
                  tariffs.push(tariff)
                  // Высчитываем сроки для каждого тарифа
                  let terms = []
                  fee.pricing_model.pricing_model_items.forEach((item) => {
                    item.transaction_conditions.forEach((condition) => {
                      if (condition.key === 'transaction_info.terminal_info.term') {
                        if (!terms.includes(condition.value)) {
                          terms.push(condition.value)
                        }
                      }
                    })
                  })
                  termsByTariff[fee.pricing_model.name] = terms.sort(function (a, b) {
                    return parseInt(a) - parseInt(b)
                  })
                }
              })
            }
            if (Object.prototype.hasOwnProperty.call(response.data, 'merchantFees')) {
              let tariffs = []
              let termsByTariff = []
              let collectedTariffs = []
              response.data.merchantFees.forEach((fee) => {
                if (!collectedTariffs.includes(fee.pricingModel.id)) {
                  collectedTariffs.push(fee.pricingModel.id)
                  let tariff = {
                    title: fee.pricingModel.name,
                    value: fee.pricingModel.id,
                  }
                  tariffs.push(tariff)
                  // Высчитываем сроки для каждого тарифа
                  let terms = []
                  fee.pricingModel.pricingModelItems.forEach((item) => {
                    item.transactionConditions.forEach((condition) => {
                      if (condition.key === 'transaction_info.terminal_info.term') {
                        if (!terms.includes(condition.value)) {
                          terms.push(condition.value)
                        }
                      }
                    })
                  })
                  termsByTariff[fee.pricingModel.name] = terms.sort(function (a, b) {
                    return parseInt(a) - parseInt(b)
                  })
                }
              })
              context.commit('updateTariffs', tariffs)
              context.commit('updateTermsByTariff', termsByTariff)
              context.commit('updateCollectedTariffs', collectedTariffs)
            }
          }
        })
        .catch(function (error) {
          context.commit('updateLoadingTariffs', false)
          console.log(error, 'error in getActualTariffs')
        })
    },
    resetCalculations(context) {
      let calculationSelected = {
        amount: 0,
        customer_overpayment_amount: 0,
        customer_total_payments: 0,
        recurring_payment: 0,
      }
      context.commit('updateCalculationSelected', calculationSelected)
    },
  },
}
