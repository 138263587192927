<template>
  <b-modal
    no-close-on-backdrop
    hide-footer
    centered
    id="calculator-modal"
    @shown="modalShown"
    :width="cardMaxWidth"
    :disabled="loadingTariffs"
    title="Калькулятор расчетов">
    <div class="content-wrapper">
      <div class="fields">
        <div class="mb-1" style="font-weight: 500">Способ расчета</div>
        <div class="d-flex align-center flex-column mb-2">
          <b-button-group>
            <b-button
              class="switch-button"
              :variant="buttonTypeVariant('cart_amount')"
              width="50%"
              @click="changeAmountType('cart_amount')">
              Сумма заявки
            </b-button>
            <b-button
              class="switch-button"
              :variant="buttonTypeVariant('settlement_amount')"
              width="50%"
              @click="changeAmountType('settlement_amount')">
              Сумма к получению
            </b-button>
          </b-button-group>
        </div>
        <div class="mb-1" style="font-weight: 500">{{ summLabel }}</div>
        <b-form-input
          size="sm"
          class="mb-2 height36"
          id="summ"
          type="number"
          v-model="summ"
          @update="debouncedGetCalculations"></b-form-input>
        <div class="mb-1" style="font-weight: 500">Выберите тариф</div>
        <multiselect
          v-model="selectedTariff"
          :options="optionsTariffs()"
          track-by="title"
          label="title"
          :searchable="false"
          :allow-empty="false"
          @input="tariffChanged()"
          :deselect-label="$i18n.t('service.remove')"
          :select-label="$i18n.t('service.add')"
          :selected-label="$i18n.t('service.optionSelected')"
          :placeholder="multiselectPlaceholder()"></multiselect>
        <div class="d-flex align-left flex-column mb-2">
          <div class="mb-1 mt-2" style="font-weight: 500">Cрок</div>
          <b-button-group ref="TariffButtonGroup" class="mb-2">
            <b-button
              v-for="(item, index) in termOptions"
              class="narrow-switch-btn"
              :variant="buttonTermsVariant(item)"
              @click="switchTerm(item)"
              :style="'min-width: ' + getButtonWidth() + 'px; padding-left: 2px;padding-right: 2px;'">
              {{ item }}
            </b-button>
          </b-button-group>
        </div>
      </div>
      <b-table hover :items="items">
        <template v-slot:cell(value)="data">
          <div style="text-align: right" v-if="data.item.value">
            {{ properMRub(data.item.value) }}
            <span class="pl-2"><clipboard :data="data.item.value" /></span>
          </div>
        </template>
      </b-table>
      <div class="mt-4 pl-3 pr-3 mb-3" style="font-size: 12px">
        Расчеты являются предварительными и суммы могут отличаться в случае наличия дополнительных сервисов банка или частичного одобрения с
        первоначальным взносом
      </div>
    </div>
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { debounce } from 'lodash'
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      summ: 0,
      amountType: 'cart_amount',
      selectedTariff: null,
      selectedTerm: null,
      settingProcess: true,
      cardMaxWidth: 425,
      loadingTariffs: false,
    }
  },

  computed: {
    summLabel() {
      if (this.amountType === 'cart_amount') {
        return 'Сумма заявки'
      }
      return 'Сумма к получению'
    },
    amountName() {
      if (this.$store.state.calculator.currentConditions.amountType === 'cart_amount') {
        return 'Сумма к получению'
      }
      return 'Сумма заявки'
    },
    termOptions() {
      if (!this.selectedTariff || !this.selectedTariff.title) {
        return []
      }

      return this.$store.state.calculator.termsByTariff[this.selectedTariff.title]
    },
    items() {
      let items = [
        { name: 'Общая сумма платежей', value: this.$store.state.calculator.calculationSelected.customer_total_payments },
        { name: 'Переплата', value: this.$store.state.calculator.calculationSelected.customer_overpayment_amount },
        { name: 'Ежемесячный платеж', value: this.$store.state.calculator.calculationSelected.recurring_payment },
        { name: this.amountName, value: this.$store.state.calculator.calculationSelected.amount },
      ]
      return items
    },
  },
  watch: {},
  methods: {
    optionsTariffs() {
      return this.$store.state.calculator.tariffs
    },
    tariffChanged() {
      if (this.$store.state.calculator.termsByTariff[this.selectedTariff.title].length > 0) {
        this.selectedTerm = this.$store.state.calculator.termsByTariff[this.selectedTariff.title][0]
      } else {
        this.selectedTerm = null
      }
      this.updateCurrentConditions()
      this.$store.dispatch('calculator/resetCalculations')
      this.getCalculations()
    },
    getCalculations() {
      if (this.summ && parseInt(this.summ) > 0 && this.selectedTariff) {
        let payload = {
          pricing_model_name: this.selectedTariff.title,
        }
        payload[this.amountType] = parseInt(this.summ)
        let conditions = {
          tariff: this.selectedTariff,
          summ: parseInt(this.summ),
          amountType: this.amountType,
        }
        // Не забываем currentConditions проапдейтить
        this.updateCurrentConditions()
        // Ну и собственно запрос отправляем
        let data = { payload: payload, conditions: conditions }
        this.$store.dispatch('calculator/previewAmounts', data)
      } else {
        this.$store.dispatch('calculator/resetCalculations')
      }
    },
    changeAmountType(amountType) {
      this.amountType = amountType
      this.getCalculations()
    },
    switchTerm(item) {
      this.selectedTerm = item
      this.updateCurrentConditions()
    },
    updateCurrentConditions() {
      let currentConditions = {
        tariff: this.selectedTariff,
        summ: parseInt(this.summ),
        amountType: this.amountType,
        term: this.selectedTerm,
      }
      this.$store.commit('calculator/updateCurrentConditions', currentConditions)
      this.$store.dispatch('calculator/chooseCalculationByTerm')
    },
    modalShown() {
      this.$store.dispatch('calculator/getActualTariffs')
    },
    debouncedGetCalculations: _.debounce(function () {
      this.getCalculations()
    }, 1000),
    buttonTypeVariant(buttext) {
      if (buttext == this.amountType) {
        return 'primary'
      }
      return 'outline-primary'
    },
    buttonTermsVariant(termValue) {
      if (termValue == this.selectedTerm) {
        return 'primary'
      }
      return 'outline-primary'
    },
    getButtonWidth() {
      let buttonWidth = this.$refs.TariffButtonGroup.clientWidth / this.termOptions.length
      return buttonWidth.toString()
    },
  },
  mounted() {},
}
</script>

<style>
#calculator-modal .modal-header {
  border-bottom: 0px;
}
#calculator-modal .modal-header .close {
  padding-top: 11px;
}
#calculator-modal .modal-header .modal-title {
  text-align: center;
  width: 100%;
  color: #3d5170;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.4px;
}
#calculator-modal .table thead th {
  padding-left: 15px;
  display: none;
}
#calculator-modal .table tbody td {
  padding-left: 15px;
  padding-top: 6px;
  padding-bottom: 6px;
}
#calculator-modal .modal-body {
  padding: 0;
}
</style>
<style lang="scss" scoped>
.height36 {
  height: 36px;
}
.switch-button {
  font-size: 12px;
}
.narrow-switch-btn {
  min-width: 48px;
}
.fields {
  margin-left: 16px;
  margin-right: 16px;
}
.v-table :deep(thead) {
  display: none;
}
.enlarged-font-icon {
  font-size: 21px;
}
#calculator.dialog-layout-desktop {
  .v-card {
    padding-bottom: 50px;
  }
}
</style>
