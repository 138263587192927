const filters_config = {
  instrument_type: {
    show: true,
    options: [
      { value: '1', label: 'Банковская карта', selected: false },
      { value: '20', label: 'СБП' },
    ],
  },
  status: {
    show: true,
    options: [
      { value: 'success', label: 'Успешно', selected: true },
      { value: 'fail,timeout', label: 'Ошибка' },
      { value: 'wait,wait_internal,wait_3ds,wait_external,revise_require', label: 'Обработка' },
    ],
  },
  opcodes: {
    show: true,
    options: [
      { value: '1', label: 'Оплата' },
      { value: '2', label: 'Возврат' },
      { value: '3', label: 'Оплата (рекуррент)' },
      { value: '6', label: 'Оплата (2х стадийная)' },
      { value: '22', label: 'Возврат' },
      { value: '4', label: 'Предавторизация' },
      { value: '5', label: 'Отмена авторизации' },
      { value: '453', label: 'Опротестование' },
    ],
  },
}

export default filters_config
