function generateUUID() {
  // Простая функция для генерации UUID
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

import jwt from 'jsonwebtoken'
import axios from 'axios'
import readXlsxFile from 'read-excel-file'
import { utils, writeFileXLSX } from 'xlsx'

function decodeToken(token) {
  try {
    return jwt.decode(token)
  } catch (error) {
    console.error('Error decoding token:', error)
    return null
  }
}

export default {
  namespaced: true,
  state: {
    argLeft: [
      {
        arg: 'sales',
        label: 'PayLender.left.sales',
        link: VUE_APP_EXT_URLS_OLD_ADMINKA + 'sales',
        classes: 'sales',
      },
      {
        arg: 'payments',
        label: 'PayLender.left.payments',
        link: VUE_APP_EXT_URLS_OLD_ADMINKA + 'payments',
        classes: 'payments',
      },
      {
        arg: 'installment_plan',
        label: 'PayLender.left.installment_plan',
        link: VUE_APP_EXT_URLS_OLD_ADMINKA + 'installment_plan',
        classes: 'installment_plan',
      },
      {
        arg: 'analytics',
        label: 'PayLender.left.analytics',
        link: VUE_APP_EXT_URLS_OLD_ADMINKA + 'analytics',
        classes: 'analytics',
      },
      {
        arg: 'nocode',
        label: 'PayLender.left.nocode',
        classes: 'nocode',
      },
    ],
    argTop: [
      [],
      [],
      [],
      [],
      [
        {
          name: 'modulesAll',
          arg: 'modules',
          label: 'PayLender.argTop.payoutAll',
          classes: 'payout-all',
        },
        {
          name: 'modulestransactionsHistory',
          arg: 'modulestransactions',
          label: 'PayLender.argTop.payoutHistory',
          classes: 'payouttransactions',
        },
        {
          name: 'modulesRegistries',
          arg: 'modulesregistries',
          label: 'PayLender.argTop.modulesRegistries',
          classes: 'modulesregistries',
        },
      ],
    ],
    displayTable: {
      tableApiUrls: {
        modulesAll: {
          url: 'api/v1/merchants',
          responseObject: 'results',
        },
        modulestransactionsHistory: {
          url: 'api/v1/transactions',
          responseObject: 'transactions',
        },
        modulesRegistries: {
          url: 'api/v1/registry',
          responseObject: 'registries',
        },
      },
      columnsControl: {},
      pageOptions: [10, 15, 25, 50, 100],
      tableFilters: {
        datePicker: {
          modulesAll: {
            field: 'merchant_id',
          },
          modulestransactionsHistory: {
            field: 'created_at',
          },
          modulesRegistries: {
            field: 'created',
          },
        },
        operationTypes: {},
        status: {},
      },
    },
    apiData: {
      url: '/payment-gateway/v3/transactions',
      transactionsReceived: false,
      transactionsEmpty: true,
      cursor: {
        range: 10,
        currentOffset: 0,
        count: 0,
        next: null,
        prev: null,
        total: null,
      },
      registries: [],
      transactions: [],
      transaction: null,
      results: [],
      modulestransactions: [],
      error: '',
      psa: {},
    },
    step1: false,
    step2: false,
    step3: false,
    registry_response: {
      id: null,
      amount: '0.00',
      rows: '0',
    },
    rows: [],
    is_active: false,
    availableUsers: [],
    loadingUsers: false,
    loadingData: false,
    loadingBanks: false,
    banks: {},
    psa_access_token: '',
  },
  mutations: {
    SET_PSA(state, psa) {
      state.apiData.psa = psa
    },
    SET_PSA_ACCESS_TOKEN(state, token) {
      state.psa_access_token = token
    },
    SET_LOADING_BANKS(state, stateLoading) {
      state.loadingBanks = stateLoading
    },
    SET_BANKS(state, banks) {
      state.banks = banks
    },
    SET_AVAILABLE_USERS(state, users) {
      state.availableUsers = users
    },
    SET_LOADING_USERS(state, isLoading) {
      state.loadingUsers = isLoading
    },
    SET_LOADING_DATA(state, isLoading) {
      state.loadingData = isLoading
    },
    save_errors(state, rows) {
      state.rows = rows
    },
    error_location_reload(state, txt = 'Произошла непредвиденная ошибка. ' + '\nОбратитесь к администратору.') {
      alert(txt)
      location.reload()
    },
    updateResponse(state, data) {
      let dataname = data.dataname
      let dataCopy = JSON.parse(JSON.stringify(data[dataname]))
      if (dataname === 'loan') {
        dataCopy.forEach((loan, index) => {
          if (!loan.hasOwnProperty('customer') || !loan.customer) {
            loan.customer = {}
          }
          dataCopy[index] = loan
        })
      }
      state.apiData[dataname] = dataCopy
    },
    is_active(state, value) {
      state.is_active = value
    },
    set_transaction(state, value) {
      state.apiData.transaction = value
      if (state.apiData.transactions && state.apiData.transactions.length > 0) {
        state.apiData.transactions = state.apiData.transactions.map((transaction) => {
          if (value && transaction.id === value.id) {
            return value
          }
          return transaction
        })
      }
    },
    updateTransactionStatus(state, { transactionId, status, fio }) {
      let transaction = state.apiData.transactions.find((t) => t.id === transactionId)
      if (transaction) {
        transaction.state = status
        transaction.fio = fio
      }
    },
    confirmTransaction(state, { transactionId, state: transactionState }) {
      let transaction = state.transactions.find((t) => t.id === transactionId)
      if (transaction) {
        transaction.state = transactionState
      }
    },
  },
  actions: {
    fetchBanks({ commit }, payload) {
      commit('SET_LOADING_BANKS', true)
      const mid = payload.mid
      let url = VUE_APP_SECURE + 'api/sbp/banks'
      let banks = [{ value: null, value_bic: null, label: 'отсутствует список банков' }]
      this.$axios
        .get(url, { headers: { Mid: mid } })
        .then((response) => {
          const banksData = response.data.banks

          if (banksData && Array.isArray(banksData)) {
            banks = banksData.map((bank) => ({
              value: bank.bankId,
              label: bank.bankName,
              value_bic: bank.bankBic,
            }))
          }
          commit('SET_BANKS', banks)
        })
        .catch((error) => {
          console.error('Ошибка при получении списка банков:', error)
          this._vm.$toast.error('Ошибка при получении списка банков')
          commit('SET_BANKS', banks)
        })
        .finally(() => {
          commit('SET_LOADING_BANKS', false)
        })
    },
    fetchAvailableUsers({ commit }) {
      commit('SET_LOADING_USERS', true)

      let url = VUE_APP_IDENTITY + '/api/v1/users?limit_to=1000'
      this.$axios
        .get(url, { withCredentials: true })
        .then((response) => {
          let availableUsers = []

          response.data.users.forEach((user) => {
            if (user.permissions.includes('secure_app_payout_transactions.write') && user.email && user.email.trim() !== '') {
              availableUsers.push({
                value: String(user.email),
                label: String(user.email),
              })
            }
          })

          commit('SET_AVAILABLE_USERS', availableUsers)
        })
        .catch((error) => {
          console.error('Ошибка при получении пользователей:', error)
          this._vm.$toast.error('Ошибка при получении пользователей')
        })
        .finally(() => {
          commit('SET_LOADING_USERS', false)
        })
    },
    prepareFilterByString(context) {
      let filterByString = ''
      if (context.rootState.filtersStd.dateFrom) {
        filterByString += '&created>=' + context.rootState.filtersStd.dateFrom
      }
      if (context.rootState.filtersStd.dateTo) {
        filterByString += '&created<' + context.rootState.filtersStd.dateTo
      }
      if (filterByString && filterByString.charAt(0) === '&') {
        filterByString = filterByString.substring(1)
      }
      filterByString = encodeURIComponent(filterByString)
      context.commit('filtersStd/updateFilterByString', filterByString, { root: true })
    },
    refreshTableData(context, queryObject) {
      let activeColumnRow = context.rootState.pager.activeColumnRow
      let reso_url = context.state.displayTable.tableApiUrls[activeColumnRow]
      let url = reso_url.url
      let responseObject = reso_url.responseObject
      context.commit('pager/updateApiResponseReceived', false, { root: true })
      context.commit('pager/updateApiDataEmpty', true, { root: true })
      context.state.apiData.error = ''
      if (activeColumnRow === 'modulesAll' || activeColumnRow === 'modulestransactionsHistory') {
        let url_cid = VUE_APP_SECURE_APP + url + '/'
        let params = {}
        let page = null
        let search = null
        let action = null
        if (
          context.rootState.filtersStd.hasOwnProperty('dateFrom') &&
          context.rootState.filtersStd.hasOwnProperty('dateTo') &&
          context.rootState.filtersStd.dateFrom !== 'undefined' &&
          context.rootState.filtersStd.dateTo !== 'undefined' &&
          activeColumnRow === 'modulestransactionsHistory'
        ) {
          if (queryObject.currentOffset > 0) page = queryObject.currentOffset + 1
          if (context.rootState.filters.filtersValues.search !== '') search = context.rootState.filters.filtersValues.search
          if (context.rootState.filters.filtersValues.action !== '') action = context.rootState.filters.filtersValues.action
          params = {
            from_created_at: context.rootState.filtersStd.dateFrom || null,
            to_created_at: context.rootState.filtersStd.dateTo || null,
            limit_to: context.rootState.pager.cursor.range,
            page: page,
            search: search,
            action: action,
          }
        } else {
          if (activeColumnRow === 'modulesAll' && context.rootState.filters.filtersValues.action !== '') {
            params = {
              action: context.rootState.filters.filtersValues.action,
            }
          }
        }
        axios
          .get(url_cid, {
            params: params,
            withCredentials: true,
          })
          .then((response) => {
            //console.log(JSON.stringify(response))
            response = response.data
            context.commit('pager/updateApiResponseReceived', true, { root: true })
            if (response[responseObject].length === 0) {
              context.commit('pager/updateApiDataEmpty', true, { root: true })
              context.state.apiData.error =
                activeColumnRow === 'modulestransactionsHistory'
                  ? 'Транзакции отсутствуют.'
                  : 'Настроенные модули отсутствуют. В правом углу над таблицей находится кнопка "Действие", с помощью которой Вы можете создать и настроить модуль.'
            } else {
              context.commit('pager/updateApiDataEmpty', false, { root: true })
            }
            let object = {
              [responseObject]: response[responseObject],
              dataname: responseObject,
            }
            context.commit('updateResponse', object)
            let cursor = {
              count: response[responseObject].length,
              next: response.next,
              prev: response.previous,
              total: response.count,
            }
            context.commit('pager/updateCursor', cursor, { root: true })
            context.commit('pager/updateCurrentOffset', queryObject.currentOffset, { root: true })
          })
          .catch((error) => {
            console.log(JSON.stringify(error))
            if (error.response) {
              if (error.response.status === 401 || error.response.data.detail.includes('401')) {
                window.location = VUE_APP_SECURE_APP + 'auth_page/?reurl=' + window.location
              } else if (error.response.status === 403) {
                context.state.apiData.error =
                  'У Вас недостаточно прав для просмотра данной страницы. Пожалуйста обратитесь к администратору.'
              }
            }
            context.commit('pager/updateApiResponseReceived', true, { root: true })
            context.commit('pager/updateApiDataEmpty', true, { root: true })
          })
      } else {
        if (activeColumnRow === 'modulesRegistries') {
          url = VUE_APP_SECURE_APP + context.state.displayTable.tableApiUrls[activeColumnRow].url + '/'
        }
        if (queryObject && queryObject.type === 'cursor') {
          url += '?cursor=' + queryObject.params.cursor
        } else {
          let limit = 'limit_to=' + queryObject.params.limit_to
          url += '?' + limit
          context.dispatch('prepareFilterByString')
          let filterByString = context.rootState.filtersStd.filterByString
          if (filterByString) {
            url += '&' + 'filter_by=' + filterByString
          }
        }
        // console.log(url)
        axios
          .get(url, {
            withCredentials: true,
          })
          .then((response) => {
            response = response.data
            context.commit('pager/updateCursor', response.cursor, { root: true })
            context.commit('pager/updateCurrentOffset', queryObject.currentOffset, { root: true })
            context.commit('pager/updateApiResponseReceived', true, { root: true })
            if (response[responseObject].length === 0) {
              context.commit('pager/updateApiDataEmpty', true, { root: true })
              context.state.apiData.error = 'Нет данных'
            } else {
              context.commit('pager/updateApiDataEmpty', false, { root: true })
            }
            let object = {
              [responseObject]: response[responseObject],
              dataname: responseObject,
            }
            context.commit('updateResponse', object)
          })
          .catch((error) => {
            if (error.response && activeColumnRow === 'modulesRegistries') {
              if (error.response.status === 401 || error.response.data.detail.includes('401')) {
                window.location = VUE_APP_SECURE_APP + 'auth_page/?reurl=' + window.location
              } else if (error.response.status === 403) {
                context.state.apiData.error =
                  'У Вас недостаточно прав для просмотра данной страницы. Пожалуйста обратитесь к администратору.'
              }
            }
            context.commit('pager/updateApiResponseReceived', true, { root: true })
            context.commit('pager/updateApiDataEmpty', true, { root: true })
          })
      }
    },
    saveEditPayout(context, form) {
      let payload = form.item
      let method = 'patch'
      let urlm = ''
      if (payload.new) {
        method = 'post'
        urlm = ''
        payload.client_id = this.state.account.cid
      } else {
        if (payload.action === 'life') {
          urlm = form.item.merchant_id + '-' + form.item.id + '/'
        } else {
          urlm = form.item.merchant_id + '/'
        }
      }
      let url = VUE_APP_SECURE_APP + context.state.displayTable.tableApiUrls['modulesAll'].url + '/' + urlm
      delete payload.new
      context.commit('pager/updateApiResponseReceived', false, { root: true })
      let back = form.back
      axios({
        url: url,
        data: payload,
        withCredentials: true,
        method: method,
      })
        .then((response) => {
          if (form.back) {
            form.router.push(back)
          } else {
            context.commit('pager/updateApiResponseReceived', true, { root: true })
            this._vm.$toast.success('Настройки сохранены')
          }
        })
        .catch((error) => {
          console.log(error, 'saveEditPayout')
          let err = error.response.data[0] ? ' Error: ' + error.response.data[0] : ''
          this._vm.$toast.error(
            'К сожалению, при попытке отредактировать saveEditPayout произошла ошибка. Обратитесь в техподдержку.' + err
          )
          context.commit('pager/updateApiResponseReceived', true, { root: true })
        })
    },
    updateTransaction(context, item) {
      let link = item.link
      let state = item.state
      axios
        .get(link, {
          withCredentials: true,
        })
        .then((response) => {
          let data = response.data
          if (data.state !== state) {
            let transaction = context.state.apiData.transactions.find((tr) => tr.id === item.id)
            transaction.state = data.state
            if (data.state === 'error') transaction.error_desc = data.error_desc
          } else {
            alert('Статус заявки не изменился')
          }
        })
    },
    reopenTransaction(context, item) {
      let link = item.link
      let state = item.state
      axios
        .patch(
          link,
          {
            state: state,
          },
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          let data = response.data
          if (data.state !== state) {
            let transaction = context.state.apiData.payouttransactions.find((tr) => tr.id === item.id)
            transaction.state = data.state
            if (data.state === 'processing') transaction.secure_id = data.secure_id
            if (data.state === 'error') transaction.error_desc = data.error_desc
          } else {
            alert('Повторный запрос отправлен')
          }
        })
    },
    downloadRegistryReport(context, mid) {
      let rid = context.state.registry_response.id
      let url = VUE_APP_SECURE_APP + 'api/v1/registry/' + rid + '/'
      this.$axios
        .get(url, { responseType: 'blob', withCredentials: true })
        .then((response) => {
          var a = window.document.createElement('a')

          let blob = new Blob([response.data])
          a.href = window.URL.createObjectURL(blob)

          let d = new Date()

          a.download = d.getDate() + '_' + d.getMonth() + '_' + d.getFullYear() + '-' + d.getTime() + '-Payouts-' + rid + '-' + mid + '.xls'
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        })
        .catch((error) => {
          console.log('error in promise', error)
          alert('К сожалению, мы не смогли получить экспорт данных в ' + 'формате Excel. Пожалуйста, обратитесь в техподдержку.')
        })
    },
    downloadRegistry(context, merchant_id) {
      let input_file = document.getElementById('input_file')
      readXlsxFile(input_file.files[0]).then((rows) => {
        let request_data = {
          action: 'payout',
          merchant_id: merchant_id,
          data: [],
        }
        let fields = []
        let data = []
        let line = {}
        try {
          for (var i = 0; i < rows.length; i++) {
            line = {}
            for (var j = 0; j < rows[i].length; j++) {
              rows[i][j] = String(rows[i][j])
              if (i === 0) {
                if (rows[i][j] !== null) {
                  fields.push(rows[i][j])
                }
              } else {
                if (j < fields.length) {
                  if (rows[i][j] === null) throw 'is null'
                  line[fields[j]] = rows[i][j]
                }
              }
            }
            if (i !== 0) data.push(line)
          }
          request_data.fields = fields
          request_data.data = data
        } catch (e) {
          alert('Содершимое файла не соответствует шаблону')
          return
        }
        let url = VUE_APP_SECURE_APP + 'api/v1/registry/'
        this.$axios
          .post(url, request_data, { withCredentials: true })
          .then((response) => {
            if (!response) {
              context.commit('error_location_reload')
            } else {
              if (response.status === 201) {
                context.state.registry_response = response.data
                context.state.step1 = false
                context.state.step2 = true
              } else if (response.status === 207) {
                if (response.data.error) {
                  let errors = response.data.error
                  for (var i = 0; i < errors.length; i++) {
                    let row = errors[i].line
                    let col = rows[0].length
                    if (rows[row][col] === undefined) {
                      rows[row][col] = errors[i].error
                    } else {
                      rows[row][col] = rows[row][col] + '; ' + errors[i].error
                    }
                  }
                  context.commit('save_errors', rows)
                } else {
                  context.commit('error_location_reload')
                }
              } else {
                context.commit('error_location_reload')
              }
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                context.commit(
                  'error_location_reload',
                  'Ошибка в файле: ' + error.response.data.error + '\nПожалуйста исправьте и повторите еще раз'
                )
              }
            }
            context.commit('error_location_reload', JSON.stringify(error))
          })
      })
    },
    downloadErrors(context) {
      let rows = context.state.rows
      let ws = utils.aoa_to_sheet(rows)
      let wb = utils.book_new()
      utils.book_append_sheet(wb, ws, 'Data')
      //https://docs.sheetjs.com/docs/api/
      writeFileXLSX(wb, input_file.files[0].name.split('.')[0] + '_errors.xlsx', { bookType: 'xlsx' })
    },
    deleteErrors(context) {
      context.state.rows = []
    },
    paymentsStart(context, router) {
      if (context.state.registry_response.id) {
        context.state.step2 = false
        context.commit('pager/updateApiResponseReceived', true, { root: true })

        let url = VUE_APP_SECURE_APP + 'api/v1/registry/' + context.state.registry_response.id + '/'
        this.$axios
          .patch(url, { state: 'start' }, { withCredentials: true })
          .then((response) => {
            if (!response) {
              context.commit('error_location_reload')
            } else {
              if (response.status === 200) {
                context.commit('pager/updateApiResponseReceived', false, { root: true })
                if (router.history.current.path.includes('modulesregistries')) {
                  location.reload()
                } else {
                  router.push('/pay_merchant/nocode/modulesregistries')
                }
              } else {
                context.commit('error_location_reload')
              }
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                context.commit(
                  'error_location_reload',
                  'Ошибка в файле: ' + error.response.data.error + '\nПожалуйста исправьте и повторите еще раз'
                )
              } else {
                context.commit('error_location_reload', JSON.stringify(error.response))
              }
            }
            console.log('error in promise', error)
            console.log('error.response', error.response)
            console.log('error.request', error.request)
            console.log('Error', error.message)
            context.commit('error_location_reload', JSON.stringify(error))
          })

        context.commit('pager/updateApiResponseReceived', false, { root: true })
      } else context.commit('error_location_reload')
    },
    async getTransaction(context, id) {
      let url = VUE_APP_SECURE_APP + `api/v1/transactions/${id}/`
      await this.$axios
        .get(url, { withCredentials: true })
        .then((resp) => {
          if (resp.data && resp.data.id && resp.data.id === Number(id)) {
            context.commit('set_transaction', resp.data)
            if (resp.data.details) {
              context.commit('SET_PSA', {
                personal_info: JSON.parse(resp.data.details.personal_info),
                psa_data: resp.data.details.psa_data,
              })
            }
          } else {
            console.error('Ошибка запроса: ', resp)
          }
        })
        .catch((err) => {
          console.error('Ошибка запроса: ', err.response)
        })
    },
    async createTransaction(context, payload) {
      try {
        let url = VUE_APP_SECURE_APP + `api/v1/transactions/?mid=${payload.mid}`
        let headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
        if (context.state.apiData.psa) {
          payload.data.psa = JSON.stringify(context.state.apiData.psa)
        }
        payload.data.idempotency_key = generateUUID()
        context.commit('SET_LOADING_DATA', true)

        await this.$axios
          .post(url, payload.data, { headers: headers, withCredentials: true })
          .then((resp) => {
            resp.data.state = 'new'
            context.commit('set_transaction', resp.data)
          })
          .catch((err) => {
            console.error('Ошибка запроса: ', err.response)
          })
          .finally(() => {
            context.commit('SET_LOADING_DATA', false)
          })
      } catch (error) {
        console.error('Ошибка запроса: ', error)
        throw new Error(error.response.data.error || 'Ошибка создания транзакции')
      }
    },
    async fetchSbpStatus({ commit }, transactionId) {
      try {
        const response = await this.$axios.get(`${VUE_APP_SECURE_APP}api/v1/transactions/${transactionId}/sbp_status/`, {
          withCredentials: true,
        })
        // commit('updateTransactionStatus', { transactionId, status: response.data.status, fio: response.data.fio });
        // if (response.data.state !== 'pending') {
        //   return { status: response.data.status, fio: response.data.fio };
        // }
        return response.data
      } catch (error) {
        console.error('Ошибка запроса статуса: ', error)
        throw new Error(error.response.data.error || 'Ошибка получения статуса')
      }
    },
    async confirmSbpPayment({ commit }, transactionId) {
      commit('SET_LOADING_DATA', true)
      try {
        const response = await this.$axios.post(`${VUE_APP_SECURE_APP}api/v1/transactions/${transactionId}/sbp_confirm/`, null, {
          withCredentials: true,
        })
        // commit('confirmTransaction', { transactionId, state: response.data.state });
        if (response.status === 200) {
          return { status: 200 }
        }
        throw new Error('Ошибка подтверждения транзакции')
      } catch (error) {
        console.error('Ошибка подтверждения транзакции: ', error)
        throw new Error(error.message || 'Ошибка подтверждения транзакции')
      } finally {
        commit('SET_LOADING_DATA', false)
      }
    },
    async createBinding(context, payload) {
      let url = VUE_APP_SECURE_APP + `api/v1/bindings/?mid=${payload.mid}`
      let headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
      context.commit('SET_LOADING_DATA', true)
      if (context.state.apiData.psa) {
        payload.data.psa = JSON.stringify(context.state.apiData.psa)
      }
      await this.$axios
        .post(url, payload.data, { headers: headers, withCredentials: true })
        .then((resp) => {
          context.commit('set_transaction', resp.data)
        })
        .catch((err) => {
          console.error('Ошибка запроса: ', err.response)
        })
        .finally(() => {
          context.commit('SET_LOADING_DATA', false)
        })
    },
    async validatePsaNumber(context, payload) {
      let url = VUE_APP_CUSTOMERS + `/api/customers/phone/find?phone=${encodeURIComponent(payload)}`
      context.commit('SET_LOADING_DATA', true)
      try {
        const response = await this.$axios.get(url, { withCredentials: true })
        context.commit('SET_LOADING_DATA', false)
        return { ...response.data, success: response.data.is_customer_found }
      } catch (error) {
        console.error('Ошибка запроса: ', error.response)
        context.commit('SET_LOADING_DATA', false)
        throw new Error(error.response && error.response.data.error ? error.response.data.error : 'Ошибка проверки номера телефона')
      }
    },
    async getPsaData(context, payload) {
      const url = VUE_APP_CUSTOMERS + `/api/customers/self`
      const headers = { Authorization: `Bearer ${payload}` }
      context.commit('SET_LOADING_DATA', true)
      try {
        const response = await this.$axios.get(url, { headers: headers, withCredentials: true })
        context.commit('SET_LOADING_DATA', false)
        return { ...response.data, success: true }
      } catch (error) {
        console.error('Ошибка запроса: ', error.response)
        context.commit('SET_LOADING_DATA', false)
        throw new Error(error.response && error.response.data.error ? error.response.data.error : 'Ошибка запроса данных пользователя')
      }
    },
    async validatePsaPassportNumber(context, payload) {
      const url = VUE_APP_CUSTOMERS + `/api/customers/passport/verify`
      const request_data = {
        phone: payload.phone,
        number: payload.number,
      }
      const headers = { Authorization: `Token ${payload.token}` }
      context.commit('SET_LOADING_DATA', true)
      try {
        const response = await this.$axios.post(url, request_data, { headers: headers, withCredentials: true })
        context.commit('SET_LOADING_DATA', false)
        return { ...response.data, success: response.data.is_passport_verified }
      } catch (error) {
        console.error('Ошибка запроса: ', error.response)
        context.commit('SET_LOADING_DATA', false)
        throw new Error(error.response && error.response.data.error ? error.response.data.error : 'Ошибка проверки номера паспорта')
      }
    },
    async confirmPsaSMS(context, payload) {
      const url = VUE_APP_CUSTOMERS + `/api/sms/confirm`
      const request_data = {
        phone: payload.phone,
        attempt_id: payload.attempt_id,
        code: payload.code,
      }
      const headers = { Authorization: `Token ${payload.token}` }
      context.commit('SET_LOADING_DATA', true)
      try {
        const response = await this.$axios.post(url, request_data, { headers: headers, withCredentials: true })
        context.commit('SET_LOADING_DATA', false)
        return { ...response.data, success: true }
      } catch (error) {
        console.error('Ошибка запроса: ', error.response)
        context.commit('SET_LOADING_DATA', false)
        throw new Error(error.response && error.response.data.error ? error.response.data.error : 'Ошибка проверки кода из СМС')
      }
    },
    async savePsaData(context, payload) {
      const url = VUE_APP_CUSTOMERS + `/api/customers`
      const headers = { Authorization: `Bearer ${payload.token}` }
      context.commit('SET_LOADING_DATA', true)
      context.commit('SET_PSA', payload.data)
      try {
        const response = await this.$axios.patch(
          url,
          { identity_document: payload.data.personal_info },
          { headers: headers, withCredentials: true }
        )
        context.commit('SET_LOADING_DATA', false)
        return { ...response.data, success: true }
      } catch (error) {
        console.error('Ошибка запроса: ', error.response)
        context.commit('SET_LOADING_DATA', false)
        throw new Error(error.response && error.response.data.error ? error.response.data.error : 'Ошибка сохранения данных пользователя')
      }
    },
    async registerPsaNumber(context, payload) {
      const url = VUE_APP_CUSTOMERS + `/api/customers/signup`
      context.commit('SET_LOADING_DATA', true)
      try {
        const response = await this.$axios.post(url, { phone: payload }, { withCredentials: true })
        context.commit('SET_LOADING_DATA', false)
        return { ...response.data, success: true }
      } catch (error) {
        console.error('Ошибка запроса: ', error.response)
        context.commit('SET_LOADING_DATA', false)
        throw new Error(error.response && error.response.data.error ? error.response.data.error : 'Ошибка регистрации номера телефона')
      }
    },
  },
}
