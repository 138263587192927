<template>
  <div class="wrapper-global">
    <SandboxVisual />
    <div class="wrapper-content">
      <main-sidebar />

      <div class="custom-main">
        <main-navbar />
        <slot />
      </div>
    </div>
    <div class="wrapper-footer" style="padding-left: 145px">
      <main-footer />
    </div>
    <SupportIcons />
  </div>
</template>

<script>
import MainNavbar from '@/components/Layout/MainNavbar/MainNavbar.vue'
import MainSidebar from '@/components/Layout/StdSidebar.vue'
import MainFooter from '@/components/Layout/MainFooter/MainFooter.vue'
import SupportIcons from '../components/Layout/SupportIcons.vue'
import SandboxVisual from '@/components/Layout/MainNavbar/SandboxVisual.vue'

export default {
  name: 'analytics',
  components: {
    MainNavbar,
    MainSidebar,
    MainFooter,
    SupportIcons,
    SandboxVisual,
  },
}
</script>

<style>
.wrapper-global {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.wrapper-footer {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
}

.wrapper-content {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
}
.custom-main {
  padding-left: 145px;
  padding-right: 10px;
  min-width: 1320px;
}
</style>
