const filters_config = {
  instrument_type: {
    show: true,
    options: [
      { value: '1', label: 'Банковская карта', selected: true },
      { value: '20', label: 'СБП', selected: true },
    ],
  },
  // mw_type - невидимый фильтр, который влияет на все запросы - все перечисленные опции добавляются в фильтрацию.
  // чтобы убрать фильтр, надо или убрать mw_type целиком, или убрать options из фильтра.
  mw_type: {
    show: false,
    options: [{ value: 'transaction' }],
  },
  status: {
    show: true,
    options: [
      { value: 'success', label: 'Успешно', selected: true },
      { value: 'fail,timeout', label: 'Ошибка' },
      { value: 'wait,wait_internal,wait_3ds,wait_external,revise_require', label: 'Обработка' },
    ],
  },
  opcodes: {
    show: true,
    options: [
      { value: '1', label: 'Оплата' },
      { value: '2', label: 'Возврат' },
      { value: '3', label: 'Оплата (рекуррент)' },
      { value: '6', label: 'Оплата (2х стадийная)' },
      { value: '22', label: 'Возврат' },
    ],
  },
}

export default filters_config
